import React from "react"
import styled from "@emotion/styled"
import tw, {theme} from "twin.macro"
import MaxWidthContainer from "./max-width-container"

import { css } from "@emotion/core"

const SectionStyled = styled("section")`
  ${tw`flex items-center flex-col pt-4 pb-20 sm:pt-5 px-5`};
  min-height: calc(100vh - 250px - 48px - 80px);
  @media (max-width: 768px) {
    min-height: calc(100vh - 150px - 48px - 147px);
  }
`
const StyledHeadingText = styled("h2")`
    ${tw`uppercase text-center mb-8`};
    font-size: 36px;
    text-center;
    line-height: 55px;
    @media (max-width: 768px) {
        font-size: 22px;
        line-height: 31px;
    }
`

const SmallText = styled("span")`
  ${tw`text-center block`};
  font-size: 24px;
  line-height: 50px;
  @media (max-width: 768px) {
    font-size: 20px;
    line-height: 22px;
}
`

const ServicePagesTemplate = ({children}) => {
  return (
    <MaxWidthContainer>
        <SectionStyled>
            <div 
                tw="flex items-center flex-col"  
                css={css`
                    border: 6px solid ${theme('colors.primary-light')};
                    padding: 80px 240px;
                    @media (max-width: 1280px) {
                        padding: 60px 100px;
                    }
                    @media (max-width: 1024px) {
                        padding: 60px;
                    }
                    @media (max-width: 768px) {
                        padding: 40px 20px;
                    }
                    @media (max-width: 420px) {
                        padding: 30px 20px;
                    }
                `}
            >
                    {children}
            </div>
        </SectionStyled>
    </MaxWidthContainer>
  )
}

export default ServicePagesTemplate
