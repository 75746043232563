import React, { useState } from "react"
import AnchorLink from "react-anchor-link-smooth-scroll"
import tw, {theme} from "twin.macro"
import Logo from "../svg/logo.svg"
import styled from "@emotion/styled"
import { HambrugerMenu } from "./hambruger-menu"
import MaxWidthContainer from "./max-width-container"

const NavBarWrapper = styled("div")`
  ${tw`flex px-5 lg:py-5 lg:px-10 w-full items-center`};
  ${props => props.shortLayoutVersion && tw`h-150px sm:h-250px`};
  min-height: 10rem
`

const NavBar = styled("div")`
  ${tw`flex items-center justify-center lg:justify-between flex-wrap relative`};
  ${props => props.shortLayoutVersion && tw`lg:justify-center`}
`

const HiddenOnLgScreenWrapper = styled.div`
  ${tw`block lg:hidden absolute right-5px top-42px`};
`

const NavItemsWrapper = styled.div`
  ${tw`w-full lg:flex lg:w-auto`};
  margin-top: 70px;
  @media (max-width: 1024px) {
    margin-top: 0;
  }
  ${props => !props.isMenuOpen && tw`hidden`}
`
const StyledAnchorLink = ({children, anchor}) => (
    <AnchorLink
      tw="lg:pr-10 py-0 px-0 my-3 lg:my-0 block text-lg text-gray hover:text-primary uppercase"
      href={anchor}
    >
      { children }
    </AnchorLink>
  )

const AnchorLinkWrapper = styled.div`
  ${tw`flex flex-col lg:flex-row pt-4 lg:pt-0 text-center mb-5 lg:mb-0`};
`

const HeaderTopLine = styled.div`
  ${tw`flex items-center justify-center bg-primary w-full uppercase`};
  height: 48px;
  font-size: 16px;
  @media (max-width: 1024px) {
    font-size: 14px;
  }
`

const Header = ({shortLayoutVersion}) => {
  const [isMenuOpen, toggleMenu] = useState(false)

  return (
    <div tw="flex flex-wrap">
      <HeaderTopLine>
        <span tw="text-white">WILLKOMMEN BEI MONESSA KOSMETIK</span>
      </HeaderTopLine>
        <NavBarWrapper shortLayoutVersion={shortLayoutVersion}>
          <MaxWidthContainer>
            <NavBar shortLayoutVersion={shortLayoutVersion}>
              <Logo tw="py-0 mt-14px lg:mt-0"/>
              {!shortLayoutVersion &&
              <>
                <HiddenOnLgScreenWrapper>
                  <div
                    role="button"
                    tw="cursor-pointer outline-none"
                    tabIndex={0}
                    onClick={() => toggleMenu(!isMenuOpen)}
                    onKeyDown={() => void 0}
                  >
                    <HambrugerMenu isOpened={isMenuOpen}></HambrugerMenu>
                  </div>
                </HiddenOnLgScreenWrapper>

                <NavItemsWrapper isMenuOpen={isMenuOpen}>
                <AnchorLinkWrapper>
                  <StyledAnchorLink anchor="#offer-section">
                    Angebot
                    </StyledAnchorLink>
                  <StyledAnchorLink anchor="#product-section">
                    Produkte
                  </StyledAnchorLink>
                  <StyledAnchorLink anchor="#about-section">
                    Über mich
                  </StyledAnchorLink>
                  <StyledAnchorLink anchor="#info-section">
                    Kontakt
                  </StyledAnchorLink>
                  <StyledAnchorLink anchor="#info-section">
                    Öffnungszeiten
                  </StyledAnchorLink>
                </AnchorLinkWrapper>
              </NavItemsWrapper>
              </>
              }
            </NavBar>
          </MaxWidthContainer>
        </NavBarWrapper>
    </div>
  )
}

export default Header
