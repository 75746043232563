import React from "react"
import PropTypes from "prop-types"
import {theme} from "twin.macro"
import Footer from "./footer"
import Header from "./header"
import { css, Global } from "@emotion/core"
import Helmet from 'react-helmet'
import favicon from '../images/favicon.ico'

const globalStyles = css`
  * {
    font-family: "Brandon Grotesque", serif;
    color: ${theme('colors.gray')}
  }
`

const Layout = ({ shortLayoutVersion = false, children }) => (
  <>
    <Helmet>
      <link rel="icon" href={favicon} />
    </Helmet>
    <Global styles={globalStyles} />
    <Header shortLayoutVersion={shortLayoutVersion} />
    <main>{children}</main>
    <Footer shortLayoutVersion={shortLayoutVersion} />
  </>
)

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
