import React from "react"
import { animated, useSpring, config } from "react-spring"
import {theme} from "twin.macro"

const openedTransformationConfig = {
  top: "translate(2, 7) rotate(0)",
  center: "translate(2, 19) rotate(0)",
  bottom: "translate(2, 31) rotate(0)",
}

const closedTransformationConfig = {
  top: "translate(5, 32) rotate(-45)",
  center: "translate(10, 4) rotate(45)",
  bottom: "translate(5, 32) rotate(-45)",
}

export function HambrugerMenu({ isOpened }) {
  const { top, center, bottom } = useSpring({
    to: isOpened ? closedTransformationConfig : openedTransformationConfig,
    config: config.stiff,
  })
  return (
    <svg
      width="30"
      height="30"
      viewBox="0 0 44 44"
      fill={theme('colors.primary')}
      xmlns="http://www.w3.org/2000/svg"
    >
      <animated.rect width="40" height="6" rx="3" transform={top} />
      <animated.rect width="40" height="6" rx="3" transform={center} />
      <animated.rect width="40" height="6" rx="3" transform={bottom} />
    </svg>
  )
}
