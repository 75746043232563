import React from "react"
import styled from "@emotion/styled"
import tw from "twin.macro"
import MaxWidthContainer from "./max-width-container"
import FacebookIcon from "../svg/facebook-icon.svg"
import { css } from "@emotion/core"

const ColumnsWrapper = styled("div")`
  ${tw`flex flex-wrap items-center overflow-hidden text-gray min-h-80px px-5 xl:px-0`};
  ${props => props.shortLayoutVersion && tw`justify-between`};
`

const Column = styled("div")`
  ${tw`flex my-2 px-2 w-full overflow-hidden sm:my-2 sm:px-2 sm:w-full md:my-2 md:px-2 md:w-1/3 lg:my-2 lg:px-2 lg:w-1/3 xl:my-2 xl:px-2 xl:w-1/3`};
`

const NavItemsWrapper = styled.div`
  ${tw`flex justify-center w-full lg:flex lg:w-auto`};
`

const LinkElement = styled.a`
  ${tw`pr-6 lg:pr-10 block text-white hover:text-secondary text-small last:pr-0`};
`

function Footer({shortLayoutVersion}) {
  return (
    <>
      <footer>
        <div tw="bg-primary">
          <MaxWidthContainer>
            <ColumnsWrapper shortLayoutVersion={shortLayoutVersion}>
              {!shortLayoutVersion && 
                <Column tw="items-center justify-center lg:justify-start">
                  <span tw="text-white">
                    <a tw="underline cursor-pointer" href="mailto:info@monessakosmetik.ch">
                      info@monessakosmetik.ch
                    </a>
                    &nbsp;
                    / <a tw="underline cursor-pointer" href="tel:032 622 77 55">
                        032 622 77 55
                      </a>
                  </span>
                </Column>
              }
              <Column 
                tw="order-first md:order-none"
                css={css`
                  ${shortLayoutVersion ? (
                    `justify-content: flex-start;
                      @media (max-width: 768px) {
                        justify-content :center;
                      } 
                    `) : (
                    `justify-content: center;
                      @media (max-width: 768px) {
                        flex-start;
                      }
                    `
                    )
                  
                  }
                  
                `}
              >
                <a tw="underline my-3 cursor-pointer" target="_blank" href="https://www.facebook.com/monessakosmetik.ch">
                  <FacebookIcon />
                </a>
              </Column>
              <Column tw="justify-center md:justify-end pb-5 md:pb-0">
                <span tw="text-white text-small text-center">
                  © Monessa Kosmetik / Website by <a href="https://sitewerk.ch" target="_blank" tw="underline">Sitewerk AG</a>
                </span>
              </Column>
            </ColumnsWrapper>
          </MaxWidthContainer>
        </div>
      </footer>
    </>
  )
}

export default Footer
